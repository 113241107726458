<style scoped>
.second-label {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  background-color: #fff;
}
.label {
  padding: 10px 15px;
  color: grey;
  height: 100%;
  line-height: 100%;
}
.s-active {
  transition: all 1s;
  background-color: #efefef;
  color: black;
}
</style>
<template>
  <div>
    <Tabs :value="active" @on-click="chooseItem">
      <TabPane
        v-for="(item, index) in defaultList"
        :key="index"
        :label="item.name"
        :name="item.id"
      ></TabPane>
    </Tabs>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "labelCom",
  data() {
    return {
      active: "",
      curLabel: {},
      labelList: [],
    };
  },
  props: {
    defaultList: {
      type: Array,
      default: [],
    },
  },

  watch: {
    defaultList: function (val) {
      this.labelList = this.defaultList;
      this.active = this.labelList[0].id;
    },
  },
  computed: {},
  methods: {
    chooseItem(id) {
      const item = _.find(this.defaultList, (o) => {
        return o.id == id;
      });
      this.active = item.id || "";
      this.$emit("chooseTab", item);
    },
  },

  async created() {
    this.labelList = this.defaultList;
    this.active = this.labelList[0];
  },
};
</script>
