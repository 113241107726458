<template>
  <div class="noscrollmain">
    <common-top
      :title="showTitle"
      :isShowSearch="isShowSearch && showFilter"
      @search="showModal = true"
    ></common-top>

    <label-tree-com
      :showModal="isShowALlLabel"
      :labelType="labelType"
      :refresh="labelChangeStatus"
      @chooseTabList="chooseTabItem"
      @cancel="isShowALlLabel = false"
    ></label-tree-com>

    <dept-choose-com
      :defaultEndDate="endDate"
      :defaultStartDate="startDate"
      :showModal="showModal"
      @chooseDept="chooseDept"
      :isShowDept="isShowDept"
      @cancel="showModal = false"
      :isShowTime="true"
    ></dept-choose-com>

    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <div v-show="showList && isUserPm">
      <div style="display: flex; justify-content: space-between">
        <desc-com
          :title="descTitle"
          :startDate="startDate"
          :endDate="endDate"
        ></desc-com>

        <div
          v-if="labelType == 'content' || labelType == 'user'"
          style="font-weight: bold; padding: 10px; font-size: 14px; color: #aaa"
          @click="
            isShowALlLabel = true;
            showLoad = false;
          "
        >
          <div style="color: #768096">选择标签</div>
        </div>
      </div>

      <label-list-data
        :startDate="startDate"
        :endDate="endDate"
        :labelType="labelType"
        :refresh="labelChangeStatus"
        :defaultList="chooseLabelList"
        @chooseTab="chooseLabelTabItem"
      ></label-list-data>

      <list-com
        @chooseListItem="chooseUserListItem"
        :columns="column"
        :title="columnTitle"
        :showLoadMore="false"
        :showList="dataList"
        :comHeight="comHeight"
        :huizongList="[huizongData]"
      ></list-com>

      <load-more
        v-if="showRefreshDataCmp"
        @refresh="getDeptUserStatus"
        :load-status="isLoadMore"
        :isShow="loadMoreShow"
      ></load-more>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";
import moment from "moment";
import { statsPost, getUserPm } from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import pieChart from "../../components/pieChart.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import listCom from "../../components/listCom.vue";
import deptChooseCom from "../../components/deptLevelChooseCom.vue";
import descCom from "../../components/descCom.vue";
import noPassCom from "../../components/noPassCom.vue";
import loadMore from "../../components/loadMoreData.vue";
import labelCom from "../../components/labelCom.vue";
import labelKeyWordCom from "../../components/labelKeyWordCom.vue"; // 关键字组件
import keywordSearchCom from "../../components/keywordSearchCom.vue"; // 关键字检索
import labelTreeCom from "../../components/labelTreeCom.vue"; // 标签组件 部门树显示
import labelListData from "../../components/labelListData.vue"; // 标签显示组件

import { TASK_TYPE_NEW, MID_TYPE } from "./labelDef";

import { getLabelObj } from "../../api/cacheDept";
export default {
  name: "deptJifen",
  components: {
    pieChart,
    loadingCmp,
    commonTop,
    diaLogliu,
    listCom,
    deptChooseCom,
    descCom,
    noPassCom,
    loadMore,
    labelCom,
    labelKeyWordCom,
    // labelNewOrder1Com,
    // labelNewAllCom,
    labelTreeCom,
    keywordSearchCom,
    labelListData,
  },

  props: {
    showTitle: {
      type: String,
      default: "标签统计",
    },
    // 显示loadMore 加载组件
    showRefreshDataCmp: {
      type: Boolean,
      default: false,
    },

    //  UserLabel  ContentLabel  Keyword
    taskType: {
      type: String,
      default: "",
    },
    // user dept  userWz
    midType: {
      type: String,
      default: "",
    },

    // 标签类型
    labelType: {
      type: String,
      default: "",
    },
    columnTitle: {
      type: String,
      default: "name",
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    selfColumn: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      huizongData: {}, // 汇总数据
      chooseLabelList: [],
      isShowKeyWord: false,

      isShowALlLabel: false,
      dataTimer: null,
      comHeight: 7,

      loadMoreShow: false,
      isLoadMore: true,

      descTitle: "", // 描述说明
      isShowSearch: false, // 是否显示搜索框
      isShowDept: false, // 超管部门选择
      isUserPm: true, // 是否有权限

      showModal: false, // 显示部门弹框
      deptIdArr: [], // 选择部门
      level: 3,
      startDate: "",
      endDate: "",

      detailInfo: {}, // 单位详情

      showList: true,
      deptData: [],
      dataList: [],

      column: [
        {
          name: "阅读数量",
          value: "count",
        },

        {
          name: "点赞数量",
          value: "like",
        },
        {
          name: "分享数量",
          value: "share",
        },
        {
          name: "评论数量",
          value: "comment",
        },
        // {
        //   name: "优质评论",
        //   value: "commentView",
        // },
      ],

      showLoad: false,
      deptType: "单位",
      deptUserOption: {},
      dataNumOption: {},

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      userTimer: null,

      labelChangeStatus: false, // 通知标签组件，重新请求数据
    };
  },
  methods: {
    chooseTabItem(tabItem) {
      console.log(" 选中制定标签this.curTabItem ------", tabItem);
      if (0 == tabItem.length) {
        return;
      }
      this.curTabItem = this.getAllChildId([tabItem[0]]);
      this.chooseLabelList = tabItem;
      // 查找部门为 childDeptList
      this.dataList = [];
      this.showLoad = false;
      this.isShowALlLabel = false;
      this.isShowKeyWord = false;
      this.getDeptUserStatus();
    },

    getAllChildId(deptList) {
      let idArr = [];
      // console.log("getAllChildId------", deptList);
      for (const iterator of deptList) {
        idArr.push(iterator.id);
        if (iterator.children) {
          for (const level3 of iterator.children) {
            idArr.push(level3.id);
          }
        }
      }
      // console.log("idArr-------", _.uniq(idArr));

      return _.uniq(idArr);
    },

    chooseLabelTabItem(tabItem) {
      console.log("-curTabItem ------", tabItem);
      this.curTabItem = this.getAllChildId([tabItem]);

      // 查询数据
      // 查找部门为 childDeptList
      this.dataList = [];
      this.showLoad = false;
      this.isShowALlLabel = false;
      this.isShowKeyWord = false;
      this.getDeptUserStatus();
    },
    // 选择部门
    chooseDept(emitData) {
      this.labelChangeStatus = !this.labelChangeStatus;
      this.deptIdArr = emitData.deptIdArr;
      this.startDate = emitData.startDate;
      this.endDate = emitData.endDate;
      this.level = emitData.level;

      this.dataList = [];

      this.getDeptUserStatus();

      this.showModal = false;
    },

    chooseUserListItem(item) {},
    clearTimmerFun() {
      if (this.dataTimer) {
        clearTimeout(this.dataTimer);
      }
    },

    async getDeptUserStatus() {
      this.clearTimmerFun();
      try {
        if (this.showLoad) {
          return;
        }
        this.descTitle = "统计中..";
        this.showLoad = true;
        let _query = {
          startDate: this.startDate,
          endDate: this.endDate,
          label: this.curTabItem,
          // label: ["1671691734871a9a52c0d"],
          midType: MID_TYPE[this.midType],
          taskType: TASK_TYPE_NEW[this.taskType],
          deptIdArr: this.deptIdArr,
          level: this.level,
          limit: 20,
          skip: this.dataList.length,
        };

        const ret = await statsPost("/label/labelTjNew", _query);

        if (ret.status) {
          if (this.dataTimer) {
            clearTimeout(this.dataTimer);
          }

          this.dataTimer = setTimeout(() => {
            this.showLoad = false;
            this.getDeptUserStatus();
          }, 2000);

          this.descTitle = "";
          return;
        }

        this.showLoad = false;
        this.descTitle = "";

        if (ret.ret.length == 0) {
          this.showLoad = false;
          this.loadMoreShow = true;
          this.isLoadMore = false;
          return;
        }

        // 显示加载
        this.isLoadMore = true;
        this.loadMoreShow = true;

        this.dataList = _.concat(
          this.dataList,
          _.reverse(_.sortBy(_.get(ret, ["ret"], []), "count"))
        );

        let labelUserObj = await getLabelObj("content");

        // comment

        this.huizongData = {
          count: 0,
          deptName: "汇总",
          countPerDay: 0,
          userStatusCount: 0,
        };

        for (const iterator of this.dataList) {
          if (iterator.userLabelId) {
            iterator["userLabelName"] =
              labelUserObj[iterator.userLabelId] || "测试";
          }
          if (iterator.contentLabelId) {
            iterator["contentLabelName"] =
              labelUserObj[iterator.contentLabelId] || "";
          }
          let countRate = 0;
          if (iterator["count"]) {
            countRate = ((iterator.perCount || 0) / iterator["count"]) * 100;
          }
          iterator["countRate"] = `${countRate.toFixed(1)}%`;

          const count = iterator.count || 0;
          let countPerDay = 0;
          if (iterator.userStatusCount) {
            countPerDay = count / iterator.userStatusCount;
          }

          iterator["countPerDay"] = countPerDay.toFixed(2);

          this.huizongData["count"] += iterator["count"];
          this.huizongData["userStatusCount"] += iterator["userStatusCount"];
        }
        let countPerDay = 0;
        if (this.huizongData.userStatusCount) {
          countPerDay =
            this.huizongData.count / this.huizongData.userStatusCount;
        }

        this.huizongData["countPerDay"] += countPerDay.toFixed(2);
      } catch (error) {
        console.log("error-------", error);
        this.dailogshow = true;
        this.dailogoptions.content = "请稍后重试";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
      }
    },
  },

  destroyed() {
    this.clearTimmerFun();
  },
  async created() {
    this.startDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");

    if (this.selfColumn && this.selfColumn.length > 0) {
      this.column = this.selfColumn;
    }

    const userPm = getUserPm();
    if (userPm) {
      this.level = userPm.datalevel;

      this.deptIdArr = JSON.parse(localStorage.getItem("pmDeptIdArr"));
      if (userPm.pmValue && userPm.statsPmStatus) {
        this.isShowSearch = true;
        this.isShowDept = true;
      }

      this.$Message.info("请选择标签，查询数据");
      // this.getUserList();
    } else {
      this.isUserPm = false;
    }
  },
};
</script>

<style scoped>
.deptMainTop {
  text-align: center;
  /* color: white; */
  padding: 15px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.deptMainTop .box-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}
.deptMainTop .box-item span {
  flex: 1;
  color: #b57464;
  font-size: 16px;
}

.deptMainTop .box-item .numColr,
.numColor {
  font-size: 16px;
  font-weight: bold;
  color: #866060;
}
.deptMainTop .box-item .descColor,
.descColor {
  color: #bbb;
  overflow: hidden;
  font-size: 10px;
}
</style>
