<template>
  <label-cmpt
    :showTitle="'热点分析'"
    :showRefreshDataCmp="false"
    :taskType="'cLabel'"
    :midType="'dept'"
    :labelType="'content'"
    :selfColumn="selfColumn"
  ></label-cmpt>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

import labelCmpt from "./hotChooseLableCmpt.vue";

export default {
  name: "hotLabelDeptStats",
  components: {
    labelCmpt,
  },

  data() {
    return {
      selfColumn: [
        {
          name: "总访问次数",
          value: "count",
        },

        {
          name: "总人数",
          value: "userStatusCount",
        },

        {
          name: "访问频次",
          value: "countPerDay",
        },
      ],
    };
  },
  methods: {},

  destroyed() {},
  async created() {},
};
</script>

<style scoped>
</style>
